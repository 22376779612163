import React, { useCallback, useMemo, useState } from "react";
import POOL_JSON from './OPT_POOL_V1.json';
import DCI_JSON from './DCI_New.json'
import LOCK_JSON from './OPT_LOCK_V2.json';
import { Contract as DefiContract } from '@ethersproject/contracts';
import { Typography, Tag, Space, InputNumber, Button, Table } from 'antd';
import dayjs from 'dayjs';
import {
    updateChainId,
    parseEther,
    formatAddress,
    formatTokenAddress,
    formatBaseAmount,
    formatTradePrice,
    formatDateTime,
    getProvider,
    connectContractToSigner,
    getLockAddress
  } from './util';
import { utils } from 'ethers';
const DCIStatus = [
    'INVALID',
    'LOCKED',
    'REQUEST_WITHDRAW',
    'WITHDRAWN',
    'EXERCISED',
    'EXPIRED'
]
const states = [
    'OPEN',
    'LOCKED',
    'PENDING_SETTLEMENT',
    'DELIVERED',
    'EXPIRED',
    'PENDING_REFUND',
    'WITHDRAW'
  ];
const DCIColumns = (tokens, groups) => [
    {
        title: 'NFT id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        colSpan: 1,
        render: (value) => {
            return <Typography>{Number(value)}</Typography>
        }
    },
    {
        title: 'Subscription Time',
        key: 'tradeTime',
        dataIndex: 'tradeTime',
        width: 200,
        render: (value, record) => {
            return <Typography>{dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss')}</Typography>
        }
    },
    {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount',
        width: 200,
        render: (value, record) => {
            const group = groups[record.instrumentId];
            const token = record.investInBase ? tokens[group.base] : tokens[group.contra];
            return <Typography>{utils.formatUnits(value, token.decimals)} {token.name}</Typography>
        }
    }, {
        title: 'APY',
        key: 'apy',
        dataIndex: 'apy',
        render: (value, record) => {
            return <Typography>{(utils.formatUnits(value, 18) / 100).toFixed(2)}%</Typography>
        }
    }, {
        title: 'Interest',
        key: 'interest',
        dataIndex: 'interest',
        width: 200,
        render: (value, record) => {
            const group = groups[record.instrumentId];
            const token = record.investInBase ? tokens[group.base] : tokens[group.contra];
            return <Typography>{utils.formatUnits(value, token.decimals)} {token.name}</Typography>
        }
    }, {
        title: 'Settlement Date',
        key: 'expiry',
        dataIndex: 'expiry',
        width: 200,
        render: (value, record) => {
            return <Typography>{dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss')}</Typography>
        }
    }, {
        title: 'Target Price',
        key: 'strike',
        dataIndex: 'strike',
        render: (value, record) => {
            const group = groups[record.instrumentId];
            const base = tokens[group.base];
            const contra = tokens[group.contra];
            return <Typography>{utils.formatUnits(value, 18 + contra.decimals - base.decimals)}</Typography>
        }
    }, {
        title: 'Type',
        key: 'investInBase',
        dataIndex: 'investInBase',
        width: 200,
        render: (value, record) => {
            const group = groups[record.instrumentId];
            const base = tokens[group.base];
            const contra = tokens[group.contra];
            if (value) {
                return <Typography>Sell {base.name} for {contra.name}</Typography>
            } else {
                return <Typography>Buy {base.name} with {contra.name}</Typography>
            }
        }
    }, {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 200,
        render: (value, record) => {
            const texts = [DCIStatus[value]]
            if (['EXERCISED', 'EXPIRED'].includes(DCIStatus[value])) {
                const group = groups[record.instrumentId];
                const base = tokens[group.base];
                const contra = tokens[group.contra];
                const px = utils.formatUnits(record.settlementPrice, 18 + contra.decimals - base.decimals);
                texts.push(`PX=${px}`);
                const token = record.investInBase ? tokens[group.base] : tokens[group.contra];
                const amount = utils.formatUnits(record.amount.add(record.interest), token.decimals);
                console.log('amount', amount, record.amount,record.interest, token.displayDecimals, token.decimals, token.name, record.investInBase);
                if (DCIStatus[value] === 'EXERCISED') {
                    const exerciseToken = record.investInBase ? tokens[group.contra] : tokens[group.base];
                    if (record.investInBase) {
                        texts.push(`${(amount * px).toFixed(exerciseToken.displayDecimals)} ${exerciseToken.name}`)
                    } else {
                        texts.push(`${(amount / px).toFixed(exerciseToken.displayDecimals)} ${exerciseToken.name}`)
                    }
                } else {
                    texts.push(`${amount} ${token.name}`)
                }
            }
            return <>
                {texts.map(text => (<Tag key={text}>{text}</Tag>))}
            </>
        }
    },{
        title: 'HID',
        dataIndex: ['hedgeTrade','id'],
        key: 'hedgeTrade.id',
        render: (value)=>{
            console.log('hedgeTrade id',value);
            return <Typography>{Number(value)}</Typography>
        }
      },
      {
        title: 'HPremium',
        dataIndex: ['hedgeTrade','premium'],
        key: 'hedgeTrade.premium',
        render: (value,rec)=>{
            return formatBaseAmount(value,rec.hedgeTrade)
        }
      },
      {
        title: 'HCcy1',
        dataIndex: ['hedgeTrade','baseTokenAdd'],
        key: 'hedgeTrade.baseTokenAdd',
        render: formatTokenAddress
      },
      {
        title: 'HCcy2',
        dataIndex: ['hedgeTrade','contraTokenAdd'],
        key: 'hedgeTrade.contraTokenAdd',
        render: formatTokenAddress
      },
      {
        title: 'HCALL_PUT',
        dataIndex: ['hedgeTrade','isCall'],
        key: 'hedgeTrade.isCall',
        render: isCall => (isCall ? 'CALL' : 'PUT')
      },
      {
        title: 'HMaturity (YYYY-MM-DD)',
        dataIndex: ['hedgeTrade','maturity'],
        key: 'hedgeTrade.maturity',
        render: value=>{
            return <Typography>{dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss')}</Typography>
        }
      },
      {
        title: 'HStrike',
        dataIndex: ['hedgeTrade','strike'],
        key: 'hedgeTrade.strike',
        render: (value,rec)=>formatTradePrice(value,rec.hedgeTrade)
      },
      {
        title: 'HIndexPrice',
        dataIndex: ['hedgeTrade','indexPrice'],
        key: 'hedgeTrade.indexPrice',
        render: (value,rec)=>formatTradePrice(value,rec.hedgeTrade)
      },
      {
        title: 'HUpdatedTime',
        dataIndex: ['hedgeTrade','ts'],
        key: 'hedgeTrade.ts',
        render: value=>{
            return <Typography>{dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss')}</Typography>
        }
      },
      {
        title: 'HState',
        key: 'hedgeTrade.state',
        dataIndex: ['hedgeTrade','state'],
        render: stateIdx => {
          const state = states[stateIdx];
          let color = 'green';
          if (state === 'LOCKED') {
            color = 'orange';
          }
          if (state === 'WITHDRAW') {
            color = 'red';
          }
          return (
            <Tag color={color} key={state}>
              {state}
            </Tag>
          );
        }
      }
];
const key = 'dci_trades';
export const DCITrades = ({ chainConfig, provider, message, library }) => {
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(10);
    const [max,setMax]=useState(0);
    const [trades, setTrades] = useState([]);
    const [loading, setLoading] = useState(false);
    const tryF = (f) => {
        return async (...args) => {
            try {
                await f(...args);
            } catch (e) {
                message.error({ content: e.message, key });
                setLoading(false);
            }
        }
    }
    const load = useCallback(async () => {
        setLoading(true);
        const dci = new DefiContract(chainConfig.dci2, DCI_JSON.abi, provider);
        const max_hex=await provider.getStorageAt(chainConfig.dci2,360);
        const max=Number(max_hex);
        setMax(max);
        const effectiveEnd=Math.min(end,max);
        if(start>=effectiveEnd){
            throw new Error('Invalid range');
        }
        const tradeIds = Array.from({ length: effectiveEnd - start }, (v, i) => start + i);
        const trades = await dci.getTrades(tradeIds);
        console.log('trades', trades);
        setTrades(trades);
        setLoading(false);
        const hedgeIds = trades.map(t => t.hedgeId);
        const lock = new DefiContract(chainConfig.lockAddress, LOCK_JSON.abi, provider);
        const hedgeTrades = await lock.getTrades(hedgeIds);
        console.log('hedgeTrades', hedgeTrades);
        setTrades(trades.map((t, i) => ({ ...t, hedgeTrade: hedgeTrades[i] })));
        setLoading(false);
    }, [chainConfig, start, end]);
    const poolBuy=async(tradeId)=>{
        const pool=new DefiContract(chainConfig.optionPool,POOL_JSON.abi,provider);
        const signed = connectContractToSigner(
            pool,
            {
              transactionName: 'Buy Option via Pool'
            },
            library
          );
        const tx=await signed.buyTrades(chainConfig.lockAddress,[tradeId]);
        message.info({content:'Buy transaction sent, waiting for confirmation',key});
        await tx.wait();
        message.success({content:'Buy success',key});
    };
    const columns = useMemo(() => {
        return [...DCIColumns(chainConfig.tokens2, chainConfig.groups),
            {
                title: 'Actions',
                dataIndex: 'id',
                render: (v, record)=>{
                    const canBuy=states[record.hedgeTrade &&record.hedgeTrade.state]==='OPEN';
                    return <>
                    <Button onClick={()=>window.open(`${chainConfig.openSeaURL}/${v}`,'_blank')} size='small'>OpenSea</Button>
                    {canBuy&&<Button onClick={tryF(()=>poolBuy(v))} size='small'>Buy</Button>}
                    </>
                }
            }
        ];
    }, [trades]);
    const updateStart=(v)=>{
        setStart(v);
        setEnd(v+10);
    }
    return <Space direction="vertical">
        <Space>
            <Typography>Start</Typography>
            <InputNumber value={start} onChange={updateStart} />
            <Typography>End</Typography>
            <InputNumber value={end} onChange={setEnd} />
            {max>0&&<Typography>Max {max}</Typography>}
            <Button onClick={tryF(load)} loading={loading}>Load</Button>
        </Space>
        <Table dataSource={trades} columns={columns} loading={loading}/>
    </Space>
};