import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Typography, Button, Space, Modal, Dropdown, Row, Select, InputNumber, Checkbox } from 'antd';
import { Contract as DefiContract } from '@ethersproject/contracts';
import POOL_JSON from './OPT_POOL_V1.json';
import DIVIDER_JSON from './Divider.json';
import ERC20 from './OurToken.json';
import { formatEther, formatNumber,connectContractToSigner,parseEther,isNative } from './util';
import ButtonGroup from 'antd/lib/button/button-group';
import { useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import WETHABI from './WETH.json';
const { abi: ercABI } = ERC20;


const DepositWithdrawDialog = ({ onClose, onOK, open, chainConfig, title }) => {
    const [token, setToken] = useState('USDC');
    const [amount, setAmount] = useState(0);

    return <Modal title={title} open={open} onOk={() => onOK(token, amount)} onCancel={onClose}>
        <Space size={16} direction='vertical' className='w100'>
            <Space size={16} className='w100'>
                <Typography.Text>Token</Typography.Text>
                <Select value={token} onChange={setToken} options={Object.keys(chainConfig.tokens).map(k => ({ value: k, label: k }))} />
            </Space>
            <Space size={16} className='w100'>
                <Typography.Text>Amount</Typography.Text>
                <InputNumber value={amount} onChange={setAmount} />
            </Space>
        </Space>
    </Modal>
};
const SwapDialog = ({ onClose, onOK, open,chainConfig }) => {
    const [token, setToken] = useState('USDC');
    const [amount, setAmount] = useState(0);
    const [toToken, setToToken] = useState('BTC');
    return <Modal title="Deposit" open={open} onOk={() => onOK(token, amount, toToken)} onCancel={onClose}>
        <Space size={16} direction='vertical' className='w100'>
            <Space size={16} className='w100'>
                <Typography.Text>Swap Token</Typography.Text>
                <Select value={token} onChange={setToken} options={Object.keys(chainConfig.tokens).map(k => ({ value: k, label: k }))} />
            </Space>
            <Space size={16} className='w100'>
                <Typography.Text>Amount</Typography.Text>
                <InputNumber value={amount} onChange={setAmount} />
            </Space>
            <Space size={16} className='w100'>
                <Typography.Text>To Token</Typography.Text>
                <Select value={toToken} onChange={setToToken} options={Object.keys(chainConfig.tokens).map(k => ({ value: k, label: k }))} />
            </Space>
        </Space>
    </Modal>
};
const get_url=(optionPool)=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    urlParams.set('acc', optionPool);
    return `${window.location.href.split('?')[0]}?${urlParams.toString()}`;
}
const key='pool';
export const Pool = ({ chainConfig, provider,message }) => {
    const [balances, setBalances] = useState({});
    const [sharePrice, setSharePrice] = useState(0);
    const [depositDialog, setDepositDialog] = useState(false);
    const [withdrawDialog, setWithdrawDialog] = useState(false);
    const [swapDialog, setSwapDialog] = useState(false);
    const {library,account}=useEthers();
    const [dciStockBalances, setDCIStockBalances] = useState({});
    const [myBalances, setMyBalances] = useState({});
    const [dciDividendId, setDCIDividendId] = useState(0);
    const loadPool=useCallback(async()=>{
      if (!(chainConfig && chainConfig.optionPool)) {
        return;
      }
      const dividerContract=new DefiContract(chainConfig.dci_stock,DIVIDER_JSON.abi,provider);
      const balances = {};
      //todo change to Divider getERCBalance
      const balancesWeb3=await dividerContract.ercBalancesFor(chainConfig.optionPool, Object.values(chainConfig.tokens).map(t=>t.addr));
      Object.keys(chainConfig.tokens).forEach((currency,idx) => {
          console.log('currency is ', currency);
          const balance = balancesWeb3[idx];
          balances[currency] = formatNumber(formatEther(balance, currency, chainConfig.tokens), chainConfig.tokens[currency].displayDecimals);
          console.log(currency, balances);
      });
      setBalances({ ...balances });
      const poolContract = new DefiContract(chainConfig.optionPool, POOL_JSON.abi, provider);
      const sharePrice = await poolContract.getSharePrice();
      setSharePrice(formatNumber(formatEther(sharePrice, 'ETH', chainConfig.tokens), chainConfig.tokens['ETH'].displayDecimals));

    });
    const loadDCI = useCallback(async () => {
        if (!(chainConfig && chainConfig.optionPool)) {
          return;
        }
        const dividerContract=new DefiContract(chainConfig.dci_stock,DIVIDER_JSON.abi,provider);
        const stockBalances = {};
        const stockBalancesWeb3=await dividerContract.ercBalancesFor(chainConfig.dci_stock, Object.values(chainConfig.tokens).map(t=>t.addr));
        Object.keys(chainConfig.tokens).forEach((currency,idx) => {
            console.log('currency is ', currency);
            const balance = stockBalancesWeb3[idx];
            stockBalances[currency] = formatNumber(formatEther(balance, currency, chainConfig.tokens), chainConfig.tokens[currency].displayDecimals);
            console.log(currency, stockBalances);
        });
        setDCIStockBalances({ ...stockBalances });

        const myBalances = {};
        const myBalancesWeb3=await dividerContract.ercBalancesFor(account, [chainConfig.optionPool,chainConfig.dci_stock]);
        myBalances['Pool'] = formatNumber(utils.formatUnits(myBalancesWeb3[0], 6), 2);
        myBalances['DCI'] = formatNumber(utils.formatUnits(myBalancesWeb3[1], 6), 2);
        setMyBalances({ ...myBalances });
        const dividentId=await dividerContract.getCurrentRound();
        setDCIDividendId(Number(dividentId));
    }, [chainConfig, provider])
    useEffect(() => {
      (async function load() {
        await loadPool();
        await loadDCI();
      })();
    }, [chainConfig,account]);
    const deposit=async (token, amount)=>{
        const ercTokenAddress=chainConfig.tokens[token].addr;
        const ercContract = new DefiContract(ercTokenAddress, ercABI, provider);
        const ercSigned = connectContractToSigner(
          ercContract,
          {
            transactionName: 'Approve ERC spending'
          },
          library
        );
        const isNative=(ercAddress)=>{
            if (chainConfig && chainConfig.tokens) {
                return Object.values(chainConfig.tokens).reduce((pre, cur) => {
                  if (pre) {
                    return pre;
                  }
                  return cur.addr === ercAddress && cur.native;
                }, false);
              }
              return false;
        }
        const defiAmount=parseEther(amount.toString(), token, chainConfig.tokens);
        const checkBalance = async () => {
            const balance = await ercSigned.balanceOf(account);
            if (defiAmount.gt(balance)) {
              if (isNative(ercTokenAddress)) {
                message.loading({ content: 'Tokenize ETH to WETH', key });
                const weth = new DefiContract(ercTokenAddress, WETHABI, provider);
                const wethSigned = connectContractToSigner(
                  weth,
                  {
                    transactionName: 'Tokenize ETH to WETH'
                  },
                  library
                );
                const transaction = await wethSigned.deposit({
                  value: defiAmount.sub(balance)
                });
                await transaction.wait(1);
              } else {
                throw new Error(
                  'Insufficient ERC balance at contract ' + ercTokenAddress
                );
              }
            }
          };
          const checkAllowance = async () => {
            const allowance = await ercSigned.allowance(account, chainConfig.optionPool);
            if (defiAmount.gt(allowance)) {
              message.loading({ content: 'Approving ERC20', key });
              const approval = await ercSigned.approve(
                chainConfig.optionPool,
                defiAmount
              );
              await approval.wait(1);
            }
          };
          message.loading({ content: 'Checking Balance', key });
          await checkBalance();
          message.loading({ content: 'Checking Token Allowance', key });
          await checkAllowance();
          message.loading({ content: 'ERC approved, deposit into pool', key });
          const poolContract = new DefiContract(chainConfig.optionPool, POOL_JSON.abi, provider);
          const poolContractSigned=connectContractToSigner(poolContract,{transactionName:'Deposit'},library);
          const tx=await poolContractSigned.deposit(ercTokenAddress, defiAmount);
          await tx.wait(1);
          message.success({ content: 'Deposit successful', key });
          setDepositDialog(false);
    }
    const withdraw=async (token, amount)=>{
        const poolContract = new DefiContract(chainConfig.optionPool, POOL_JSON.abi, provider);
        const defiAmount=parseEther(amount.toString(), token, chainConfig.tokens);
        const poolContractSigned=connectContractToSigner(poolContract,{transactionName:'Withdraw'},library);
        const tx=await poolContractSigned.withdraw(chainConfig.tokens[token].addr,defiAmount);
        message.loading({ content: 'Withdraw request sent,waiting for confirmation', key });
        await tx.wait(1);
        message.success({ content: 'Withdraw successful', key });
        setWithdrawDialog(false);
    }
    const swap=async (token, amount, toToken)=>{
        const poolContract = new DefiContract(chainConfig.optionPool, POOL_JSON.abi, provider);
        const defiAmount=parseEther(amount.toString(), token, chainConfig.tokens);
        const poolContractSigned=connectContractToSigner(poolContract,{transactionName:'Withdraw'},library);
        const tx=await poolContractSigned.swapAsset(chainConfig.tokens[token].addr,defiAmount,chainConfig.tokens[toToken].addr,3000);
        message.loading({ content: 'Swap asset request sent,waiting for confirmation', key });
        await tx.wait(1);
        message.success({ content: 'Swap asset successful', key });
        setSwapDialog(false);
    }
    const announceDividend=async ()=>{
        const dividerContract=new DefiContract(chainConfig.dci_stock,DIVIDER_JSON.abi,provider);
        const dividerContractSigned=connectContractToSigner(dividerContract,{transactionName:'Announce Dividend'},library);
        const tx=await dividerContractSigned.announceDividend(Object.values(chainConfig.tokens).map(t=>t.addr),10000);
        message.loading({ content: 'Announce Dividend request sent,waiting for confirmation', key });
        await tx.wait(1);
        message.success({ content: 'Announce Dividend successful', key });
    }
    const claimDividend=async ()=>{ 
      const roundIds = Array.from({ length: dciDividendId  }, (v, i) =>  i);
      console.log('roundIds',roundIds);
      const dividerContract=new DefiContract(chainConfig.dci_stock,DIVIDER_JSON.abi,provider);
      const dividerContractSigned=connectContractToSigner(dividerContract,{transactionName:'Claim Dividend'},library);
      const tx=await dividerContractSigned.claimDividend(roundIds);
      message.loading({ content: 'Claim Dividend request sent,waiting for confirmation', key });
      await tx.wait(1);
      message.success({ content: 'Claim Dividend successful', key });
    }
    const tryF=(f)=>{
        return async(...args)=>{
            try{
                await f(...args);
            }catch(e){
                message.error({content:e.message,key});
            }
        }
    }
    if(!chainConfig){
        return <div>Invalid Chain Config</div>
    }
    return <Space direction="vertical" size={16}>
        <Card title="DCI PnL Balances">
            <p>
              <div>Address</div>
              <div>{chainConfig && chainConfig.dci_stock}</div>
            </p>
            <p>
              <div>Shares: {myBalances['DCI']}</div>
              <div>Dividend Round: {dciDividendId}</div>
            </p>
            {Object.keys(dciStockBalances).map(currency => <div key={currency}>{currency}:{dciStockBalances[currency]}</div>)}
            <br />
            <ButtonGroup>
              <Button onClick={loadDCI}>Refresh</Button>
              <Button onClick={tryF(announceDividend)}>Announce Dividend</Button>
              <Button onClick={tryF(claimDividend)}>Claim Dividend</Button>
          </ButtonGroup>
        </Card>
        <Card title="Hedge Pool Balances">
            <p>
              <div>Pool Address</div>
              <div>{chainConfig && chainConfig.optionPool}</div>
            </p>
            <p>
              <div>Share Price: {sharePrice}</div>
              <div>Shares: {myBalances['Pool']}</div>
            </p>
            
            {Object.keys(balances).map(currency => <div key={currency}>{currency}:{balances[currency]}</div>)}
            <br />
            <ButtonGroup>
              <Button onClick={loadPool}>Refresh</Button>
              <Button onClick={() => setDepositDialog(true)}>Deposit</Button>
              <Button onClick={()=> setWithdrawDialog(true)}>Withdraw</Button>
              <Button onClick={()=> setSwapDialog(true)}>Swap</Button>
              <Button onClick={()=>{window.open(get_url(chainConfig.optionPool), '_blank').focus();}}>Trades</Button>
          </ButtonGroup>
        </Card>
        
        <DepositWithdrawDialog title='Deposit' open={depositDialog} onClose={() => setDepositDialog(false)} onOK={tryF(deposit)} chainConfig={chainConfig} />
        <DepositWithdrawDialog title='Withdraw' open={withdrawDialog} onClose={() => setWithdrawDialog(false)} onOK={tryF(withdraw)} chainConfig={chainConfig} />
        <SwapDialog open={swapDialog} onClose={() => setSwapDialog(false)} onOK={tryF(swap)} chainConfig={chainConfig} />
        
    </Space>
}