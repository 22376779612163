import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { getProvider } from './util';

import reportWebVitals from './reportWebVitals';
import { DAppProvider, Mainnet, Polygon,Arbitrum } from '@usedapp/core';

const config = {
  readOnlyUrls: {
    [Mainnet.chainId]: getProvider(Mainnet.chainId),
    [Polygon.chainId]: getProvider(Polygon.chainId),
    [Arbitrum.chainId]: getProvider(Arbitrum.chainId),
  }
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
